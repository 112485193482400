// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--1E8F3";
export var footer__inner = "footer-module--footer__inner--2Ci5o";
export var footer__list = "footer-module--footer__list--2t7Yx";
export var footer__footerwrap = "footer-module--footer__footerwrap--2uqgG";
export var footer__footerwrap_sp = "footer-module--footer__footerwrap_sp--3UZT7";
export var footer__footerwrap_sp__flex = "footer-module--footer__footerwrap_sp__flex--188n2";
export var footer__nav = "footer-module--footer__nav--12tKF";
export var footer__nav_sp = "footer-module--footer__nav_sp--1EgL_";
export var footer__nav_small = "footer-module--footer__nav_small--k_eLY";
export var footer__box_pc = "footer-module--footer__box_pc--K253v";
export var footer__box_sp = "footer-module--footer__box_sp--Mp5ni";
export var footer__box1 = "footer-module--footer__box1--25qY_";
export var footer__box2 = "footer-module--footer__box2--2yT57";
export var footer__logo_pc = "footer-module--footer__logo_pc--BF4_E";
export var footer__logo_sp = "footer-module--footer__logo_sp--1h3TG";