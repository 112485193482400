import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { COMPANY_SITE_URL, PRIVACY_POLICY_URL } from '@/constants/url';
import { pagesPath } from '@/lib/pagesPath';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useCookies } from "react-cookie";
import { useLocation } from "@reach/router"
import jsCookies from 'js-cookie';

export const CookieParts: React.FC = () => {
  const gtmCookie = "gatsby_gdpr_google_tagmanager";
  const cdomain = ".uniforce.co.jp";
  const [uCookies, setCookie, removeCookie] = useCookies();
  const getlocation = useLocation();
  const regex = new RegExp('(.+?)(\..+)$', '');
  let gethost = "localhost";
  Object.entries(uCookies).map(([key, value]) => (
      (() => {
        if (typeof getlocation.hostname !== `undefined`) {
          if(getlocation.hostname.match(cdomain)){
            gethost = cdomain;
          }else{
            //gethost = getlocation.hostname.replace(/(.+?)(\..+)$/, '$2');
            gethost = `.${getlocation.hostname}`;
          }
        }
        if (uCookies.gatsby_gdpr_google_tagmanager == `false` || typeof uCookies.gatsby_gdpr_google_tagmanager === `undefined`) {
          if (key.match(/^_ga/) || key === `_fbp`) {
            if(gethost == `localhost`){
              removeCookie(key);
            }else{
              removeCookie(key, { path: `/`,domain : gethost });
            }
          } else {
            return false;
          }
        }else{
        }
      })()
    )
  );
  
  const setCookieClose = () => {
    //setCookie(gtmCookie, false, {path: '/', maxAge: 604800}); // 60*60*24*7=604800
    jsCookies.set(gtmCookie, 'false', { expires: 7, path: '/' })
    document.getElementsByClassName('CookieConsent')[0].remove();
  }
  
  const css = ` .CookieConsent {
                  align-items: center !important;
                  z-index: 10000 !important;
                  width: calc(100% - 50px) !important;
                  margin: 0 25px !important;
                  padding: 23px 40px;
                  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
                  border: 2px solid #ffffff;
                  border-bottom: 0;
                  border-radius: 20px 20px 0px 0px;
                  background: rgba(45, 118, 224, 0.87) !important;
                }
                .CookieConsent .closebtn {
                  position: absolute;
                  right: 20px;
                  top: 20px;
                  font-size: 0;
                  width: 24px;
                  height: 24px;
                  border: 0;
                  outline: 0;
                  cursor: pointer;
                  background: transparent;
                }
                .CookieConsent .closebtn:after ,
                .CookieConsent .closebtn:before {
                  content: "";
                  position: absolute;
                  top: 0;
                  width: 2px;
                  height: 24px;
                  background-color: #ffffff;
                }
                .CookieConsent .closebtn:before {
                  transform: rotate(-45deg);
                }
                .CookieConsent .closebtn:after {
                  transform: rotate(45deg);
                }
                .CookieConsent .textboxwrap {
                  padding-right: 24px;
                  margin: 0 !important;
                  margin-right: 24px !important;
                  border-right: 1px solid #ffffff;
                }
                .CookieConsent .textwrap {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.1px;
                }
                .CookieConsent .textwrap .cc_title {
                  display: none;
                }
                .CookieConsent .textwrap .cc_text {}
                .CookieConsent .textwrap .link {
                  margin-right: 2em;
                  text-align: right;
                }
                .CookieConsent .textwrap .link a {
                  text-decoration: underline;
                  color: #ffffff;
                }
                .CookieConsent .btnwrap {
                  flex-shrink: 0;
                  margin-right: 35px;
                }
                .CookieConsent .btnwrap .btn {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 !important;
                  margin-left: 20px !important;
                  height: 40px;
                  font-size: 16px;
                  line-height: 1.0;
                  letter-spacing: 0.96px;
                }
                .CookieConsent .btnwrap #rcc-confirm-button {
                  width: 188px;
                  border-radius: 60px !important;
                  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
                  color: #2d76e0 !important;
                  background-color: #ffffff !important;
                }
                .CookieConsent .btnwrap #rcc-decline-button {
                  width: max-content;
                  padding: 0 !important;
                  color: #ffffff !important;
                  background-color: transparent !important;
                }
              @media screen and (max-width:870px) {
                .CookieConsent {
                  flex-wrap: nowrap !important;
                  flex-direction: column;
                  padding: 40px 35px 20px;
                  margin: 0 15px !important;
                  width: calc(100% - 30px) !important;
                  
                  overflow-y: scroll;
                  max-height: 100vh;
                }
                .CookieConsent .closebtn {}
                .CookieConsent .textboxwrap {
                  flex: 1 0 auto !important;
                  padding-right: 0;
                  margin-right: 0 !important;
                  border-right: 0px;
                  padding-bottom: 20px;
                  margin-bottom: 20px !important;
                  border-bottom: 1px solid #ffffff;
                }
                .CookieConsent .textwrap {
                  font-size: 10px;
                  line-height: 20px;
                  letter-spacing: 0.08px;
                }
                .CookieConsent .textwrap .cc_title {
                  display: block;
                  margin-bottom: 20px;
                  text-align: center;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.1px;
                  font-weight: bold;
                }
                .CookieConsent .textwrap .link {
                  margin-top: 40px;
                  margin-right: 0;
                }
                .CookieConsent .btnwrap {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 0 40px;
                  margin-right: 0;
                  width: 100%;
                }
                .CookieConsent .btnwrap .btn {
                  height: 45px;
                  margin-left: 0 !important;
                  font-size: 14px;
                  letter-spacing: 0.84px;
                }
                .CookieConsent .btnwrap #rcc-confirm-button {
                  width: 143px;
                }
                .CookieConsent .btnwrap #rcc-decline-button {
                }
              }
              `;
  
  return (
    <CookieConsent
      location="bottom"
      buttonText="同意する"
      enableDeclineButton
      setDeclineCookie={false}
      declineButtonText="同意しない"
      buttonClasses="btn"
      declineButtonClasses="btn"
      buttonWrapperClasses="btnwrap"
      contentClasses="textboxwrap"
      cookieName={gtmCookie}
      style={{}}
      onDecline={() => {
        //setCookie(gtmCookie, false, {path: '/', maxAge: 604800}); // 60*60*24*7=604800
        jsCookies.set(gtmCookie, 'false', { expires: 7, path: '/' });
      }}
    >
      <div className="textwrap"><p className="cc_title">クッキーポリシーの取得に関しての同意</p><p className="cc_text">お客様がウェブサイトを訪問されると、お客様のブラウザ上に情報を保存または取得することがあります。この情報は、お客様、お客様の好み、またはお客様のデバイスに関するもので、ほとんどの場合、サイトをお客様の期待通りに動作させるために使用されます。この情報は通常、直接お客様を特定するものではありませんが、よりパーソナライズされたウェブ体験を提供することができます。</p>
        <p className="link"><a href="https://document.uniforce-cloud.com/cookie-policy/index.html" target="_blank" rel="noreferrer">クッキーポリシーを確認</a></p></div>
      <button className="closebtn"
        onClick={() => setCookieClose()}
      >閉じる</button>
      <style>{css}</style>
    </CookieConsent>
  );
};

