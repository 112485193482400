import React, { useEffect, useMemo, useState } from 'react'
import * as Styles from './layout.module.scss'
import { Footer } from '@/components/general/footer'
import { Header } from '@/components/general/header'
import { CookieParts } from '@/components/general/Cookie';

export function Layout({ children, location }) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'inherit'
    }
  }, [visible])

  const pathname = useMemo(() => {
    return location.pathname
  }, [location])

  return (
    <>
      <Header visible={visible} setVisible={setVisible} pathname={pathname} />

      <main className={Styles.layoutWrapper}>{children}</main>

      <Footer />
      <CookieParts />
    </>
  )
}
