import classNames from 'classnames'
import { Link, withPrefix } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import * as Styles from './header.module.scss'
import { pagesPath } from '@/lib/pagesPath'

export function Header({ visible, setVisible, pathname, ...props }) {
  const [scrollY, setScrollY] = useState(0)

  /** スクロール値を保存する */
  const scrollHandler = useCallback(() => {
    setScrollY(window.scrollY)
  }, [])

  /** スクロール値が100以上であればtrueを返す */
  const isScroll = useMemo(() => scrollY >= 100, [scrollY])

  useEffect(() => {
    // スクロールイベントを検知する
    window.addEventListener('scroll', scrollHandler, { passive: false })
  }, [scrollHandler])

  return (
    <header
      {...props}
      className={classNames(
        Styles.header,
        Styles.header__fixed,
        isScroll && Styles.header__scroll
      )}
    >
      <button
        className={classNames(
          Styles.header__sp_btn,
          visible && Styles.header__sp_btn_visible
        )}
        onClick={() => setVisible(!visible)}
      >
        {visible ? (
          <img src={withPrefix('/header_icon_menu-close.svg')} alt="" />
        ) : (
          <img src={withPrefix('/header_icon_menu.svg')} alt="" />
        )}
      </button>
      <div
        className={classNames(
          Styles.header__container,
          visible && Styles.header__container__visible_sp
        )}
      >
        <div className={Styles.header__inner_left}>
          <div className={Styles.header__logo_top}>
            <Link to={pagesPath.$url()}>
              {pathname === '/' ? (
                <img
                  src={withPrefix('/lp_service_logo2.svg')}
                  alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
                  width={182}
                />
              ) : (
                <>
                  <img
                    src={withPrefix('/lp_service_logo2.svg')}
                    alt="Uniforce(ユニフォース)クラウド決算開示管理サービス"
                    width={182}
                  />
                </>
              )}
            </Link>
          </div>

          <ul
            className={classNames(pathname !== '/' && Styles.other_page_links)}
          >
            <li className={Styles.header__nav_tool}>
              <AnchorLink
                to={pagesPath.tool.$url()}
                stripHash
                onAnchorLinkClick={() => setVisible(false)}
                className={Styles.header__nav_item}
              >
                主な機能
              </AnchorLink>
            </li>
            <li className={Styles.header__nav_security}>
              <AnchorLink
                to={pagesPath.security.$url()}
                stripHash
                onAnchorLinkClick={() => setVisible(false)}
                className={Styles.header__nav_item}
              >
                セキュリティ
              </AnchorLink>
            </li>
            <li className={Styles.header__nav_plan}>
              <AnchorLink
                to={pagesPath.plan.$url()}
                stripHash
                onAnchorLinkClick={() => setVisible(false)}
                className={Styles.header__nav_item}
              >
                料金プラン
              </AnchorLink>
            </li>
            <li className={Styles.header__nav_faq}>
              <AnchorLink
                to={pagesPath.faq.$url()}
                stripHash
                onAnchorLinkClick={() => setVisible(false)}
                className={Styles.header__nav_item}
              >
                よくある質問
              </AnchorLink>
            </li>
            <li className={Styles.header__nav_news}>
              <AnchorLink
                to={pagesPath.news.$url()}
                stripHash
                onAnchorLinkClick={() => setVisible(false)}
                className={Styles.header__nav_item}
              >
                お知らせ
              </AnchorLink>
            </li>
            <li className={classNames(Styles.header__nav_contact)}>
              <AnchorLink
                to={pagesPath.contact.$url()}
                stripHash
                onAnchorLinkClick={() => setVisible(false)}
                className={Styles.header__nav_item}
              >
                お問合せ
              </AnchorLink>
            </li>
          </ul>
        </div>

        <div className={Styles.header__inner_right}>
          <ul>
            <li className={Styles.header__pc_nav}>
              <AnchorLink
                className={Styles.header__signup_btn}
                to={pagesPath.demo.$url()}
              >
                無料デモ
              </AnchorLink>
            </li>
            <li>
              <AnchorLink
                className={Styles.header__download_btn}
                to={pagesPath.download.$url()}
              >
                資料請求
              </AnchorLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}
